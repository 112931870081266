<template>
  <div>
    <div :class="{'error-value': isError}" @mouseover="open = isError && true" @mouseout="open = false">{{content}}</div>
    <fm-poper :value="open" position="right-end">
      <div class="long-remark">
        <div v-for="(item, index) in (errorMsgList || [])" :key="index">
          {{item}}
        </div>
      </div>
    </fm-poper>
  </div>
</template>

<script>
export default {
  name: 'valuColumn',
  props: {
    isError: Boolean,
    errorMsgList: Array,
    content: [String, Number]
  },
  data () {
    return {
      open: false
    }
  }
}
</script>

<style lang="less" scope>
.error-value {
  color: #F4628F;
}
.long-remark {
  max-width: 300px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 10px 15px;
}
.remark {
  overflow: hidden;
  max-width: 400px;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>
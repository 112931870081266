<template>
<div>
  <fm-form-dialog
    :form-title="title"
    :open-dialog.sync="openDialog"
    :form-parms="batchFormParms"
    :old-data="hisData"
    :mask-closable="false"
    :on-change="onFormChange"
    label-alone
    submitBtnLabel="预览"
    label-align="left"
    form-width="800px"
    @formSubmit="formSubmit"
    @handleClose="openDialog = false">
  </fm-form-dialog>
  <fm-modal v-model="modal" v-if="modal" width="1100px">
    <fm-title :title-text="title + '预览'" v-loading="loading"  :note-text="titleText" @clickTitleMenu="clickTitleMenu" :titleMenus="[{key: 'save', label: '保存'}]"></fm-title>
      <div style="width: 100%;height: calc(100vh - 200px);">
        <fm-table-new
          :columns="useColumns"
          :auto-height="true"
          border="row"
          :simple-filter="false"
          :data-list="tableData"
          :stripe="false"
          size="large"
          emptyPlaceholder="-">
          <div slot="empty" style="margin: 30px 0;">
            <img src="/static/images/null.png" style="width: 40px;"/>
            <div>暂无数据</div>
          </div>
        </fm-table-new>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  workerWageRequest
} from '../../../api'

import ValueColumn from './valueColumn'

export default {
  props: {
    jobTitleTypeList: Array,
    workerList: Array,
    changeWageLevelType: String,
    wageJobTitleList: Array,
    wageLevelList: Array,
    columnList: Array,
    title: String,
    type: String,
    valueKey: String,
    formatValue: Function
  },
  computed: {
    titleText () {
      return '总数:' + this.batchData.length + ' ' + '异常:' + this.batchData.filter(v => v.isError).length
    },
    useColumns () {
      if (!this.valueKey) {
        return this.columns
      }
      let monthList = []
      let data = [{
        title: '',
        fixed: 'left',
        field: 'workerName'
      }]
      this.batchData.forEach(v => {
        if (!monthList.includes(v.month)) {
          monthList.push(v.month)
        }
      })
      monthList.sort()
      console.log(monthList, 'monthList')
      monthList.forEach(month => {
        data.push({
          title: month,
          field: month + '_value',
          search: false,
          render: (h, rowData) => {
            let text = rowData[month + '_value']
            if (rowData[month + '_hisvalue'] && rowData[month + '_hisvalue'] !== rowData[month + '_value']) {
              text = rowData[month + '_hisvalue'] + ' -> ' + (rowData[month + '_value'] || '无')
            }
            return h(ValueColumn, {
              props: {
                content: text,
                isError: rowData[month + '_isError'],
                errorMsgList: rowData[month + '_errorMsgList']
              }
            })
          }
        })
      })
      return data
    },
    tableData () {
      if (!this.valueKey) {
        return this.batchData
      }
      let dataMap = {}
      this.batchData.forEach(v => {
        if (!dataMap[v.performanceWorkerId]) {
          dataMap[v.performanceWorkerId] = {
            workerName: v.workerName
          }
        }
        dataMap[v.performanceWorkerId][v.month + '_value'] = this.formatValue ?  this.formatValue(v) : v[this.valueKey]
        dataMap[v.performanceWorkerId][v.month + '_hisvalue'] = v.hisData ? (this.formatValue ? this.formatValue(v.hisData) : (v.hisData[this.valueKey] || '无')) : null
        dataMap[v.performanceWorkerId][v.month + '_errorMsgList'] = v.errorMsgList
        dataMap[v.performanceWorkerId][v.month + '_isError'] = v.isError
      })
      return Object.keys(dataMap).map(key => dataMap[key])
    },
    columns () {
      let data = [{
        title: '月份',
        sort: true,
        field: 'month'
      },
      {
        title: '员工姓名',
        sort: true,
        field: 'workerName'
      },
      {
        title: '身份证号',
        sort: true,
        field: 'idNo'
      },
      {
        title: '岗位类型',
        sort: true,
        field: 'jobTitleType'
      },
      {
        title: '岗位等级',
        sort: true,
        field: 'wageJobTitleTitle'
      },
      {
        title: '薪级等级',
        sort: true,
        field: 'wageLevelName'
      },
      {
        title: '补贴工资',
        sort: true,
        field: 'subsidy'
      },
      {
        title: '是否异常',
        sort: true,
        field: 'isErrorText'
      },
      {
        title: '异常说明',
        sort: true,
        field: 'errorMsgList'
      }]
      data.forEach(v => {
        v.render = (h, rowData) => {
          let text = rowData.newData[v.field]
          if (['jobTitleType', 'wageJobTitleTitle', 'wageLevelName', 'subsidy'].includes(v.field) && rowData.hisData && rowData.hisData[v.field] && rowData.hisData[v.field] !== rowData.newData[v.field]) {
            text = rowData.hisData[v.field] + ' -> ' + (rowData.newData[v.field] || '无')
          } else if (v.field === 'errorMsgList') {
            text = rowData.newData.errorMsgList.join(',')
          }
          return h('div', text || '-')
        }
      })
      return data
    },
    batchFormParms: {
      get () {
        let data = [{
          type: 'select',
          label: '是否调整全体员工',
          selectDatas: [{key: 1, label: '是'}, {key: -1, label: '否'}],
          key: 'allWorkers',
          check: {
            required: true
          }
        },
        {
          type: 'multipleChoose',
          label: '调整员工',
          selectDatas: this.workerList,
          key: 'performanceWorkerIds',
          check: {
            required: true
          },
          show (data) {
            return data.allWorkers === -1
          }
        },
        {
          type: 'monthPicker',
          label: '开始月份',
          key: 'startMonth',
          check: {
            required: true
          }
        },
        {
          type: 'monthPicker',
          label: '结束月份',
          key: 'endMonth',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否调整岗位类型',
          selectDatas: [{key: 1, label: '是'}, {key: -1, label: '否'}],
          editType: 'chooseChange',
          key: 'changeJobTitleType',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '岗位类型',
          selectDatas: this.jobTitleTypeList,
          editType: 'jobTitleType',
          key: 'jobTitleType',
          show (data) {
            return data.changeJobTitleType === 1
          },
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否调整岗位等级',
          editType: 'chooseChange',
          selectDatas: [{key: 1, label: '是'}, {key: -1, label: '否'}],
          key: 'changeWageJobTitle',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '岗位等级',
          editType: 'wageJobTitle',
          getSelectDatas: (data) => {
            return this.wageJobTitleList.filter(v => data.changeJobTitleType !== 1 || (data.jobTitleType && v.data.jobTitleType === data.jobTitleType))
          },
          key: 'wageJobTitleId',
          show (data) {
            return data.changeWageJobTitle === 1
          },
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否调整薪级等级',
          selectDatas: [{key: 1, label: '是'}, {key: -1, label: '否'}],
          editType: 'chooseChange',
          key: 'changeWageLevel',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '薪级调整方式',
          // selectDatas: [{key: 'up', label: '默认涨薪'}, {key: 'id', label: '选择薪级'}, {key: 'num', label: '输入薪级值'}],
          selectDatas: [{key: 'id', label: '选择薪级'}, {key: 'num', label: '输入薪级值'}],
          key: 'changeWageLevelType',
          editType: 'wageLevel',
          show (data) {
            return data.changeWageLevel === 1
          },
          check: {
            required: true
          }
        },
        {
          type: 'select',
          getSelectDatas: (data) => {
            return this.wageLevelList.filter(v => data.changeJobTitleType !== 1 || (data.jobTitleType && v.data.jobTitleType === data.jobTitleType))
          },
          label: '薪级等级',
          key: 'wageLevelId',
          editType: 'wageLevel',
          show (data) {
            return data.changeWageLevel === 1 && data.changeWageLevelType === 'id'
          },
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '薪级等级',
          key: 'wageLevelNum',
          editType: 'wageLevel',
          show (data) {
            return data.changeWageLevel === 1 && data.changeWageLevelType === 'num'
          },
          check: {
            required: true,
            rules: {
              type: 'integer'
            }
          }
        },
        {
          type: 'select',
          label: '是否调整补贴工资',
          selectDatas: [{key: 1, label: '是'}, {key: -1, label: '否'}],
          editType: 'chooseChange',
          key: 'changeSubsidy',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '补贴工资',
          key: 'subsidy',
          editType: 'subsidy',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          },
          show (data) {
            return data.changeSubsidy === 1
          }
        }]
        if (this.type) {
          data = data.filter(v => !v.editType || v.editType === this.type)
        }
        if (this.changeWageLevelType) {
          data = data.filter(v => v.key !== 'changeWageLevelType')
        }
        return data
      }
    }
  },
  data () {
    return {
      openDialog: false,
      modal: false,
      batchData: [],
      loading: false,
      hisData: {
        allWorkers: -1,
        changeJobTitleType: -1,
        changeWageJobTitle: -1,
        changeWageLevel: -1,
        changeWageLevelType: 'up',
        changeSubsidy: -1
      }
    }
  },
  methods: {
    open () {
      if (this.type === 'subsidy') {
        this.hisData.changeSubsidy = 1
      } else if (this.type === 'wageLevel') {
        this.hisData.changeWageLevel = 1
      } else if (this.type === 'wageJobTitle') {
        this.hisData.changeWageJobTitle = 1
      }
      if (this.changeWageLevelType) {
        this.hisData.changeWageLevelType = this.changeWageLevelType
      }
      this.openDialog = true
    },
    async clickTitleMenu () {
      if (this.loading) {
        return
      }
      let parm = this.batchData.map(v => v.newData).filter(v => !v.isError)
      let errorData = this.batchData.map(v => v.newData).filter(v => v.isError)
      if (parm.length === 0) {
        this.$notice.warning({
          title: '系统提示',
          desc: '无可更新数据，所有数据都是异常数据。'
        })
        return
      }
      const result = await this.$dialog.confirm({title: '温馨提示', content: '确定保存数据么？无异常数据' + parm.length + '条，异常数据' + errorData.length + '条，异常数据不会进行更新。'})
      if (!result) {
        return
      }
      this.loading = true
      await workerWageRequest.batchSave(parm)
      this.openDialog = false
      this.modal = false
      this.$notice.success({
        title: '系统提示',
        desc: '批量调整完成'
      })
      this.$emit('dataUpdate')
      this.loading = false
    },
    async formSubmit (data, resolve) {
      let parm = JSON.parse(JSON.stringify(data))
      parm.allWorkers = parm.allWorkers === 1
      if (parm.allWorkers) {
        parm.performanceWorkerIds = null
      }
      parm.changeJobTitleType = parm.changeJobTitleType === 1
      if (!parm.changeJobTitleType) {
        parm.jobTitleType = null
      }
      parm.changeWageJobTitle = parm.changeWageJobTitle === 1
      if (!parm.changeWageJobTitle) {
        parm.wageJobTitleId = null
      }
      parm.changeWageLevel = parm.changeWageLevel === 1
      if (!parm.changeWageLevel || parm.changeWageLevelType != 'id') {
        parm.wageLevelId = null
      }
      if (!parm.changeWageLevel || parm.changeWageLevelType != 'num') {
        parm.wageLevelNum = null
      }
      parm.changeSubsidy = parm.changeSubsidy === 1
      if (!parm.changeSubsidy) {
        parm.subsidy = null
      }
      parm.startMonth = this.$datetime.format(parm.startMonth, 'Y-M')
      parm.endMonth = this.$datetime.format(parm.endMonth, 'Y-M')
      let pass = true
      if (parm.startMonth > parm.endMonth) {
        this.$notice.warning({
          title: '系统提示',
          desc: '开始月份需要小于结束月份'
        })
        pass = false
      }
      if (!pass) {
        resolve()
        return
      }
      this.batchData = await workerWageRequest.batch(parm)
      this.batchData.forEach(v => {
        v.newData.isErrorText = v.newData.isError ? '异常' : ''
        Object.keys(v.newData).forEach((key) => {
          v[key] = v.newData[key]
        })
      })
      this.modal = true
      resolve()
    },
    onFormChange () {}
  }
}
</script>
